<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">      
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" v-if="currentUser">
          <li class="nav-item"  v-for="m in menu" :key="m.id">
            <a class="nav-link" :href="m.url">{{m.name}}</a>
          </li>
           <li class="nav-item">
            <a class="nav-link" href="#" @click.prevent="logOut">ออกจากระบบ</a>
          </li>
        </ul>
        <ul class="navbar-nav" v-else>
          <li class="nav-item">
            <a class="nav-link" href="/">เข้าสู่ระบบ</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import UserService from '../services/UserService.js'
export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      menu:[]
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },    
  },
  mounted() {
      if (this.currentUser) {
        UserService.getMenubyRoleID(this.currentUser.role_id).then((res)=>{
          this.menu = res.data
          for (let m = 0; m < this.menu.length; m++) {
            if (this.menu[m].url == '/qrcode' || this.menu[m].url == '/evaluationall' || this.menu[m].url == '/report'|| this.menu[m].url == '/header' || this.menu[m].url == '/user') {
              this.menu[m].url = this.menu[m].url+'/'+this.currentUser.headerId
            }
          // 
            
          }
        })
      }
      // console.log(this.currentUser);
    },
    methods: {
      logOut() {
      this.$store.dispatch("auth/logout");
      setTimeout(function () {
              location.reload();
            }, 500);
      this.$router.push("/");
    },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
