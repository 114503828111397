import axios from "axios";
import LinkImageService from './services/LinkImageService'
export default axios.create({
  
  baseURL: LinkImageService.getLink()+ "/api",

  // baseURL : "https://api-eva-transport.visdchiangrai.com/api",

  
  headers: {
    "Content-type": "application/json",
  }
});