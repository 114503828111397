
class LinkImageService {
  getLinkImage() {
    // return 'http://localhost:8080/uploadimage?name='
    // return 'https://api-eva-transport.visdchiangrai.com/uploadimage?name='
    return 'https://api.eva-transport.ci2ict.com/uploadimage?name='
    
  } 
  getLink() {
    // return 'http://localhost:8080'
    // return 'https://api-eva-transport.visdchiangrai.com'
    return 'https://api.eva-transport.ci2ict.com'
  } 
  getLinkEva(){
    // return 'http://localhost:8081'
    // return 'https://eva-transport.visdchiangrai.com'
    return 'https://eva-transport.ci2ict.com'
  }
}

export default new LinkImageService();