import http from "../http-common";

class IndividualService {
    getindividuals(name) {
        return http.get('/individual?name='+name);
    }
    getindividual(id) {
        return http.get('/individual/' + id);
    }
    createindividual(data) {
        return http.post('/individual', data);
    }
    updateindividual(id, data) {
        return http.put(`/individual/${id}`, data);
    }
    deleteindividual(id) {
        return http.delete('/individual/' + id);
    }
}

export default new IndividualService();