import http from "../http-common";

class UserService {
    getUsers(name) {
        return http.get('/user?name='+name);
    }
    getUser(id) {
        return http.get('/user/' + id);
    }
    createUser(data) {
        return http.post('/user', data);
    }
    updateUser(id, data) {
        return http.put(`/user/${id}`, data);
    }
    getRoles() {
        return http.get('/roles');
    }
    getMenu() {
        return http.get('/user/getmenuall');
    }
    getMenubyRoleID(id) {
        return http.get('/user/getmenu/' + id);
    }
    getMenubyRoleIDAll(id) {
        return http.get('/user/getmenuarray/' + id);
    }
    createRoleMenu(data) {
        return http.post('/role_menu', data);
    }
    deleteRoleMenu(id) {
        return http.delete(`/role_menu/` + id);
    }
}

export default new UserService();