<template>
  <div class="container">
    <div class="col mb-3 mt-3" style="text-align: right">
      <a>
        <button
          type="button"
          id="get_file"
          class="btn btn-success btn-sm"
          @click="getid(0)"
          data-bs-toggle="modal"
          data-bs-target="#AddOffice"
        >
          <i class="fa fa-plus"></i> เพิ่มสำนักงาน
        </button></a
      >
    </div>
    <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อสำนักงาน</th>
          <th scope="col">ชื่อหัวข้อแบบประเมิน</th>
          <!-- <th scope="col">รูปภาพสำนักงาน</th> -->
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.name }}
          </td>
          <td>
            <span v-html="l.title"></span>
          </td>
          <!-- <td>
            {{ l.imgPath }}
          </td> -->
          <!-- <td>{{ l.firstname }} {{ l.lastname }}</td> -->
          <td>
            <a @click="getid(l.id)">
              <button
                type="button"
                class="btn btn-warning"
                data-bs-toggle="modal"
                data-bs-target="#AddOffice"
              >
                <i class="fa fa-edit"></i></button
            ></a>&nbsp;
            <a @click="deletedata(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteOffice"
              >
                <i class="fa fa-trash"></i></button
            ></a>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div
      class="modal fade"
      id="AddOffice"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            
          </div>
          <div class="modal-body">
            <form>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">ชื่อสำนักงาน</label>
                  <input
                    v-model="user.name"
                    type="text"
                    min="1"
                    class="form-control form-control-sm"
                    id="username"
                  />
                </div>
              </div>
              <div class="card-body mt-3">
                <div class="form-group mt-3">
                  <label for="username">ชื่อหัวข้อแบบประเมิน</label>
                  <vue-editor              
              v-model="user.title"
            />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button
            id="closeduser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="DeleteOffice"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบสำนักงาน</h5>
            
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletebyid()">
              ลบข้อมูล
            </button>
            <button
            id="closeddeleteuser"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderService from "../services/HeaderService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      concert_id: 0,
      list: [],
      user: {},
      user_id: 0,
      title: "",
      roles:[]
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getid(id) {
      this.user_id = id;
      if (this.user_id != 0) {
        this.title = "แก้ไขข้อมูลสำนักงาน";
        // console.log(this.user_id);
        HeaderService.getheader(this.user_id).then((res) => {
          // console.log(res.data);
          this.user = res.data;
        });
      } else {
        this.title = "เพิ่มข้อมูลสำนักงาน";
        this.user = [];
      }
    },
    deletedata(id){
      this.user_id = id;
    },
    deletebyid(){
      HeaderService.deleteheader(this.user_id).then(()=>{
  document.getElementById("closeddeleteuser").click();
            this.getUsers();
})
    },
    save() {
      if (this.user.name == "" || this.user.name == null ) {
        alert("ชื่อหัวข้อแบบประเมิน");
      }else if (this.user.title == "" || this.user.title == null ) {
        alert("กรุณากรอกชื่อสำนักงาน");
      } else {
        var userdata = {
          title: this.user.title,
          name: this.user.name,
          createdBy:this.currentUser.id
        };
        if (this.user_id == 0) {
          HeaderService.createheader(userdata).then(() => {
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        } else {
          HeaderService.updateheader(this.user_id, userdata).then(() => {
            // console.log(res.data);
            document.getElementById("closeduser").click();
            this.getUsers();
            //       setTimeout(function () {
            //   location.reload();
            // }, 500);
            // window.scrollTo(0, 0);
          });
        }
      }
    },
    getUsers() {
      HeaderService.getheaders().then((res) => {
        this.list = res.data;
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}
.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}
body {
  background-color: gray;
}
</style>
