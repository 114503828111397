<template>
  <div class="container">
    <div v-if="type == 1">
      <div class="form-group row mt-3">
        <label for="inputPassword" class="col-sm-2 col-form-label">รูปภาพ <span style="color:red">*</span></label>
        <div class="col-sm-10">
          <img class="mt-3" v-if="path_image" :src="path_image" width="30%">
          <input id="file" class="form-control mt-3 form-control-sm" type="file" accept="image/*" @change="onFileChange" />
        </div>
      </div>
      <div class="form-group row mt-3">
        <label for="inputPassword" class="col-sm-2 col-form-label">ชื่อ-นามสกุล <span style="color:red">*</span></label>
        <div class="col-sm-10">
          <input type="text" v-model="data.first_last_name" class="form-control form-control-sm" id="inputPassword">
        </div>
      </div>
      <div class="form-group row mt-3">
        <label for="inputPassword" class="col-sm-2 col-form-label">ตำแหน่ง <span style="color:red">*</span></label>
        <div class="col-sm-10">
          <input type="text" v-model="data.position" class="form-control form-control-sm" id="inputPassword">
        </div>
      </div>
      <div class="form-group row mt-3">
        <label for="inputPassword" class="col-sm-2 col-form-label">หน่วยงาน <span style="color:red">*</span></label>
        <div class="col-sm-10">
          <!-- <input type="text" v-model="data.office" class="form-control" id="inputPassword"> -->
          <select class="form-control form-control-sm" v-model="data.office">
            <option v-for="(i, r) in office" :key="r" :value="i.id">{{ i.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="form-group row mt-3">
        <label for="inputPassword" class="col-sm-2 col-form-label">รูปภาพ <span style="color:red">*</span></label>
        <div class="col-sm-10">
          <img class="mt-3" v-if="path_image" :src="path_image" width="50%">
          <input id="file" class="form-control mt-3 form-control-sm" type="file" accept="image/*" @change="onFileChange" />
        </div>
      </div>
      <div class="form-group row mt-3">
        <label for="inputPassword" class="col-sm-2 col-form-label">ชื่อหน่วยงาน <span style="color:red">*</span></label>
        <div class="col-sm-10">
          <select class="form-contro form-control-sml" v-model="data.name">
            <option v-for="(i, r) in office" :key="r" :value="i.id">{{ i.name }}</option>
          </select>
          <!-- <input type="text" v-model="data.name" class="form-control" id="inputPassword"> -->
        </div>
      </div>
    </div>
    <div class="form-group row mt-3">
      <label for="inputPassword" class="col-sm-2 col-form-label"></label>
      <div class="col-sm-10">
        <button @click="save()" class="btn btn-success mt-3 mb-3 btn-sm">
          บันทึก
        </button>
      </div>
    </div>
    <div v-if="savestatus">

      <div class="row" ref="printMe" id="my-node">
        <table width="100%">
          <tr>
            <td style="width:25%">
              <div v-if="type == 1">
                <div class="form-group row mt-3">
                  <div class="col-sm-10">
                    &nbsp;&nbsp;&nbsp;<img class="mt-3" v-if="basepath_image" :src="basepath_image" width="50%">
                  </div>
                </div>
                <div class="form-group row mt-3">
                  &nbsp;&nbsp;&nbsp; <label for="inputPassword" style="font-weight: bold;"
                    class="col-sm-3 col-form-label">ชื่อ-นามสกุล</label>
                  <div class="col-sm-5">
                    <label for="inputPassword" class="col-form-label">{{ data.first_last_name }}</label>
                  </div>
                </div>
                <div class="form-group row mt-3">
                  &nbsp;&nbsp;&nbsp;<label for="inputPassword" style="font-weight:bold"
                    class="col-sm-3 col-form-label">ตำแหน่ง</label>
                  <div class="col-sm-5">
                    <label for="inputPassword" class="col-form-label">{{ data.position }}</label>
                  </div>
                </div>
                <div class="form-group row mt-3">
                  &nbsp;&nbsp;&nbsp;<label for="inputPassword" style="font-weight:bold"
                    class="col-sm-3 col-form-label">หน่วยงาน</label>
                  <div class="col-sm-5">
                    <label for="inputPassword" class="col-form-label">{{ data.office_name }}</label>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="form-group row mt-3">
                  <div class="col-sm-10">
                    &nbsp;&nbsp;&nbsp;<img class="mt-3" v-if="basepath_image" :src="basepath_image" width="50%">
                  </div>
                </div>
                <div class="form-group row mt-3">
                  &nbsp;&nbsp;&nbsp;<label for="inputPassword" style="font-weight:bold"
                    class="col-sm-3 col-form-label">ชื่อหน่วยงาน</label>
                  <div class="col-sm-5">
                    <label for="inputPassword" class="col-form-label">{{ data.office_name }}</label>
                  </div>
                </div>
              </div>
            </td>
            <td width="0.005%" bgcolor="#ce09a2"></td>
            <td width="2%"></td>
            <td valign="top" style="width:30%"><br /><br /><qr-code v-if="data.qrcode" :text="data.qrcode" :size=150>
              </qr-code></td>
          </tr>
        </table>
        <!-- <div class="col-md-8">  
      <div v-if="type == 1">
  <div class="form-group row mt-3">
    <div class="col-sm-10">
      <img class="mt-3" v-if="basepath_image" :src="basepath_image" width="50%">
      </div>
</div>
<div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">ชื่อ-นามสกุล</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.first_last_name}}</label>
    </div>
  </div>
  <div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">ตำแหน่ง</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.position}}</label>
    </div>
  </div>
  <div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">หน่วยงาน</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.office}}</label>
    </div>
  </div>
</div>
<div v-else>
  <div class="form-group row mt-3">
    <div class="col-sm-10">
      <img class="mt-3" v-if="basepath_image" :src="basepath_image" width="50%">
      </div>
</div>
  <div class="form-group row mt-3">
    <label for="inputPassword" class="col-sm-2 col-form-label">ชื่อหน่วยงาน</label>
    <div class="col-sm-10">
      <label for="inputPassword" class="col-sm-2 col-form-label">{{data.name}}</label>
    </div>
  </div>
</div></div> -->
        <!-- <div class="col-md-4 mt-3"><qr-code v-if="data.qrcode" :text="data.qrcode" :size=150> </qr-code></div> -->
      </div>
      <div class="col mb-3 mt-3" style="text-align: center">
        <a @click="printThis()">
          <button class="btn btn-primary">
            <i class="fa fa-file"></i> ดาวน์โหลดไฟล์ qrcode
          </button></a>
      </div>
    </div>
  </div>
</template>

<script>
import LinkImageService from '../services/LinkImageService'
import axios from "axios";
import IndividualService from '../services/IndividualService'
import OfficeService from '../services/OfficeService'
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import OfficeTypeService from "../services/OfficeTypeService";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  data() {
    return {
      data: {},
      path_image: '',
      id: '',
      statustable: true,
      type: '',
      savestatus: false,
      basepath_image: '',
      image: '',
      office: [],
      headerId:''
    }
  },
  mounted() {
    // 1 บุคคล
    // 2 หน่วยงาน
    this.id = this.$route.params.id
    this.type = this.$route.params.type
    this.headerId = this.$route.params.headerId
    console.log(this.headerId);
    if (this.type == 1) {
      this.data.type = 'individual'
    } else {
      this.data.type = 'office'
    }
    if (this.id != 0) {
      this.getdata()
      this.savestatus = true
    }
    this.getoffice()
  },
  methods: {
    getoffice() {
      OfficeTypeService.getoffice_types().then((res) => {
        this.office = res.data
      })
    },
    async printThis() {
      // console.log("printing..");
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
        width: "500px"
      };
      var name = ''
      if (this.type == 1) {
        name = this.data.first_last_name
      } else {
        name = this.data.name
      }

      const printCanvas = await this.$html2canvas(el, options);
      this.image = printCanvas;

      html2canvas(document.getElementById("my-node")).then(function (canvas) {
        var link = document.createElement("a");
        document.body.appendChild(link);
        var c = name + ".jpg";
        link.download = c;
        link.href = canvas.toDataURL();
        link.target = "_blank";
        link.click();
      });
    },
    exportToPDF() {
      var name = ''
      if (this.type == 1) {
        name = this.data.first_last_name
      } else {
        name = this.data.name
      }
      html2pdf(this.$refs.document, {
        margin: [1, 1.5, 0, 1.2],
        filename: name + ".pdf",
        jsPDF: { unit: "cm", format: "a4", orientation: "p" },
      });
      setTimeout(function () {
        location.reload();
      }, 1500);
      window.scrollTo(0, 0);
    },
    getdata() {
      if (this.type == 1) {
        IndividualService.getindividual(this.id).then((res => {
          // console.log(res.data);
          this.data = res.data
          this.path_image = this.data.path_image
          this.getbase64()
        }))

      } else {
        OfficeService.getoffice(this.id).then((res) => {
          this.data = res.data
          this.path_image = this.data.path_image
          this.getbase64()
        })
      }
    },
    getbase64() {
      var imag = this.path_image.split("/")
      // console.log(imag);
      var http = LinkImageService.getLink() + '/img?name=' + imag[4] + '/' + imag[5]
      //     console.log(LinkImageService.getLink() + '/img?name='+imag[4]+'/'+imag[5]);
      // console.log(this.basepath_image.base64);
      axios
        .get(http)
        .then((res) => {
          // console.log(res);
          this.basepath_image = res.data.base64
        })
    },
    onFileChange(evt) {
      const files = evt.target.files || evt.dataTransfer.files;
      // const selectedImage = evt.target.files[0]; // get first file
      // this.createBase64Image(selectedImage);
      if (!files.length) return;
      this.onUploadFileImage(files[0]);
      // this.createBase64Image(selectedImage);
    },
    onUploadFileImage(selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile); // appending file
      //  sending file to the backend
      //console.log(this.filename);
      var link = LinkImageService.getLink()
      console.log(this.data.type);
      if (this.type == 1) {
        this.data.type = 'individual'
      } else {
        this.data.type = 'office'
      }
      var http = link + '/uploadimage?name=' + selectedFile.name + '&&type=' + this.data.type;
      var path = link + '/uploads';
      axios
        .post(http, formData)
        .then((res) => {
          // console.log(path + res.data.path);
          this.path_image = path + res.data.path
          this.getbase64()
          // console.log(this.data);
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      if (this.type == 1) {
        if (this.data.first_last_name == null || this.data.first_last_name == "") {
          alert('กรุณากรอกชื่อ-นามสกุล')
        } else if (this.data.position == null || this.data.position == "") {
          alert('กรุณากรอกตำแหน่ง')
        } else if (this.data.office == null || this.data.office == "") {
          alert('กรุณากรอกหน่วยงาน')
        } else if (this.path_image == null || this.path_image == "") {
          alert('กรุณาเลือกไฟล์รูปภาพ')
        } else {
          if (this.id == 0) {
            var indi = {
              first_last_name: this.data.first_last_name,
              position: this.data.position,
              office: this.data.office,
              path_image: this.path_image,
              status:true,
              headerId:this.headerId
            }
            // console.log(indi);
            IndividualService.createindividual(indi).then((res) => {
              // console.log(res);
              this.id = res.data.id
              this.data.qrcode = LinkImageService.getLinkEva() + '/evaluation/' + this.type + '/' + res.data.id 
              var qrcode = {
                first_last_name: this.data.first_last_name,
                position: this.data.position,
                office: this.data.office,
                path_image: this.path_image,
                qrcode: this.data.qrcode,
                headerId:this.headerId
              }
              IndividualService.updateindividual(res.data.id, qrcode).then(() => {
                // console.log(res.data);
                alert('บันทึกสำเร็จ')
                this.savestatus = true
                this.$router.push('/addqrcode/' + this.type + '/' + this.id+ '/' + this.headerId);
                this.getdata()
              });

            })

          } else {
            this.data.qrcode = LinkImageService.getLinkEva() + '/evaluation/' + this.type + '/' + this.id
            var updatein = {
              first_last_name: this.data.first_last_name,
              position: this.data.position,
              office: this.data.office,
              path_image: this.path_image,
              qrcode: this.data.qrcode,
              headerId:this.headerId
            }
            IndividualService.updateindividual(this.id, updatein).then(() => {
              // console.log(res.data);
              alert('บันทึกสำเร็จ')
              this.savestatus = true
              this.getdata()
            });
          }
        }
      } else {
        if (this.data.name == null || this.data.name == "") {
          alert('กรุณากรอกชื่อหน่วยงาน')
        } else if (this.path_image == null || this.path_image == "") {
          alert('กรุณาเลือกไฟล์รูปภาพ')
        } else {
          if (this.id == 0) {

            var indio = {
              name: this.data.name,
              path_image: this.path_image,
              status:true,
              headerId:this.headerId
            }
            OfficeService.createoffice(indio).then((res) => {
              // console.log(res);
              this.id = res.data.id
              this.data.qrcode = LinkImageService.getLinkEva() + '/evaluation/' + this.type + '/' + res.data.id
              var qrcodeo = {
                name: this.data.name,
                path_image: this.path_image,
                qrcode: this.data.qrcode,
                headerId:this.headerId
                
              }
              OfficeService.updateoffice(res.data.id, qrcodeo).then(() => {
                // console.log(res.data);
                alert('บันทึกสำเร็จ')
                this.savestatus = true
                this.$router.push('/addqrcode/' + this.type + '/' + this.id+ '/' + this.headerId);
                this.getdata()
              });

            })

          } else {
            this.data.qrcode = LinkImageService.getLinkEva() + '/evaluation/' + this.type + '/' + this.id
            var updateoff = {
              name: this.data.name,
              path_image: this.path_image,
              qrcode: this.data.qrcode
            }
            OfficeService.updateoffice(this.id, updateoff).then(() => {
              // console.log(res.data);
              alert('บันทึกสำเร็จ')
              this.savestatus = true
              this.getdata()
            });
          }
        }
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
