import http from "../http-common";

class OfficeService {
    getoffices(name) {
        return http.get('/office?name='+name);
    }
    getoffice(id) {
        return http.get('/office/' + id);
    }
    createoffice(data) {
        return http.post('/office', data);
    }
    updateoffice(id, data) {
        return http.put(`/office/${id}`, data);
    }
    deleteoffice(id) {
        return http.delete('/office/' + id);
    }
}

export default new OfficeService();