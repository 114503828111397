<template>
<div class="container-fluid">
  <div v-if="statustable">
  <button type="button" class="btn btn-primary mt-3 btn-sm" @click="getstatus(1)">บุคคล</button>&nbsp;
<button type="button" class="btn btn-secondary mt-3 btn-sm" @click="getstatus(2)">หน่วยงาน</button>
</div>
<div v-else>
  <button type="button" class="btn btn-secondary mt-3 btn-sm" @click="getstatus(1)">บุคคล</button>&nbsp;
<button type="button" class="btn btn-primary mt-3 btn-sm" @click="getstatus(2)">หน่วยงาน</button>
</div>
<div class="container-fluid mt-3">
  <div style="text-align:right">
    <export-excel
    class   = "btn btn-success btn-sm"
    :data   = "json_data"
    :fields = "json_fields"
    :worksheet = "'ข้อมูลการประเมิน'+typename"
    :name    = "'ข้อมูลการประเมิน'+typename+'.xls'">
 
    <i class="fa fa-file-excel"></i>
 
</export-excel>
</div>
<table class="table table-striped" id="tblData" v-if="statustable">
  <thead>
    <tr>
      <th scope="col">#</th>
      <!-- <th scope="col">รูปภาพ</th> -->
      <th scope="col">ชื่อ-นามสกุล</th>
      <th scope="col">ตำแหน่ง</th>
      <th scope="col">หน่วยงาน</th>
      <th scope="col">คะแนน</th>
      <th scope="col">ข้อเสนอแนะ</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l,i) in pageOfItems" :key="i">
      <th scope="row">{{ l.no }}</th>
      <!-- <td style="width:150px"><img :src="l.path_image" style="width:100%"></td> -->
      <td>{{l.first_last_name}}</td>
      <td>{{ l.position }}</td>
      <td>{{ l.office_name }}</td>
      <td>{{ l.score }}</td>
      <td>{{ l.suggestion || '-' }}</td>
              <td>
            <a @click="deletedataEva(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteEva"
              >
                <i class="fa fa-trash"></i></button
            ></a></td>
    </tr>
  </tbody>
</table>
<table class="table table-striped" id="tblData" v-else>
  <thead>
    <tr>
      <th scope="col">#</th>
      <!-- <th scope="col">รูปภาพ</th> -->
      <th scope="col">ชื่อหน่วยงาน</th>
      <th scope="col">คะแนน</th>
      <th scope="col">ข้อเสนอแนะ</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l,i) in pageOfItems" :key="i">
      <th scope="row">{{ l.no }}</th>
      <!-- <td style="width:150px"><img :src="l.path_image" style="width:100%"></td> -->
      <td>{{l.office_name}}</td>
      <td>{{ l.score }}</td>
      <td>{{ l.suggestion || '-'}}</td>
      <td>
            <a @click="deletedataEva(l.id)">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#DeleteEva"
              >
                <i class="fa fa-trash"></i></button
            ></a></td>
    </tr>
  </tbody>
</table>
<div class="row" align="right">
        <div class="col-md-12">
          <jw-pagination
            :items="list"
            @changePage="onChangePage"
            :labels="customLabels"
            :pageSize="20"
          ></jw-pagination>
        </div>
      </div>
</div>
<div
      class="modal fade"
      id="DeleteEva"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">ยืนยันการลบข้อมูลการประเมิน</h5>
            
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-danger" @click="deletedatabyid()">
              ลบข้อมูล
            </button>
            <button
            id="closeddeleteva"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import EvaluationService from '../services/EvaluationService.js'

const customLabels = {
  first: "<<",
  last: ">>",
  previous: "<",
  next: ">",
};

export default {
  name: "Nav",  
  props: {
    msg: String,
  },
  data() {
    return {
      type:'',
      statustable:false,
      typename:'',
      list:[],
      id:'',
      json_fields: {},
        json_data: [],
        json_meta: [
            [
                {
                    'key': 'charset',
                    'value': 'utf-8'
                }
            ]
        ],
        customLabels,
      pageOfItems: [],
      pagelist:[],
      headerId:''
    }
  },
  mounted() {
    this.headerId = this.$route.params.headerId
    if (this.headerId == 0) {
      this.headerId = ''
    }
    this.getstatus(1)
    
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
      window.scrollTo(0,0);
    },
    exportTableToExcel(tableID, filename = ''){
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
    
    // Specify file name
    filename = filename?filename+'.xls':'excel_data.xls';
    
    // Create download link element
    downloadLink = document.createElement("a");
    
    document.body.appendChild(downloadLink);
    
    if(navigator.msSaveOrOpenBlob){
        var blob = new Blob(['\ufeff', tableHTML], {
            type: dataType
        });
        navigator.msSaveOrOpenBlob( blob, filename);
    }else{
        // Create a link to the file
        downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
    
        // Setting the file name
        downloadLink.download = filename;
        
        //triggering the function
        downloadLink.click();
    }
},
    deletedataEva(id){
this.id = id
    },
    deletedatabyid(){
      EvaluationService.deleteeva(this.id).then(()=>{
  document.getElementById("closeddeleteva").click();
            this.getstatus(this.type);
})
    },
getstatus(type){
  this.type = type
  if (this.type == 1) {
      this.statustable = true
      this.typename = 'บุคคล'
    }else{
      this.statustable = false
      this.typename = 'หน่วยงาน'
    }
    this.getdata()
},
getdata(){
  // console.log(this.type);
if (this.type == 1) {
  EvaluationService.getevainv(this.headerId).then((res=>{
    // console.log(res.data);
    this.list = res.data
    this.pagelist = res.data
    this.json_fields= {
            'ชื่อ-นามสกุล': 'first_last_name',
            'ตำแหน่ง': 'position',
            'หน่วยงาน': 'office_name',
            'คะแนน': 'score',
            'ข้อเสนอแนะ':'suggestion'
            }
            this.json_data = this.list
  }))
    
}else{
  EvaluationService.getevaioff(this.headerId).then((res)=>{
    this.list = res.data
    this.pagelist = res.data
    this.json_fields= {
            'ชื่อหน่วยงาน': 'office_name',
            'คะแนน': 'score',
            'ข้อเสนอแนะ':'suggestion'
            }
            this.json_data = this.list
  })
}
}
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04AA6D;
  color: white;
}
.card {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
}
body{
background-color: gray;
}
</style>
