import http from "../http-common";

class HeaderService {
    getheaders() {
        return http.get('/header');
    }
    getheader(id) {
        return http.get('/header/' + id);
    }
    createheader(data) {
        return http.post('/header', data);
    }
    updateheader(id, data) {
        return http.put(`/header/${id}`, data);
    }
    deleteheader(id) {
        return http.delete('/header/' + id);
    }
}

export default new HeaderService();